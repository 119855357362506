import React, { useState, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  CalloutCard,
  Text,
  TextField,
  Button,
  Box,
  BlockStack,
  InlineStack,
  Banner,
  Divider
} from '@shopify/polaris';
import { fetchSubscription } from './subscriptionUtils';

export default function PricingOptions() {
  const [currentPlan, setCurrentPlan] = useState('');
  const [promotionCode, setPromotionCode] = useState(''); // State for promotion code input
  const [promoMessage, setPromoMessage] = useState(''); // State for promotion code response message

  useEffect(() => {
    const shop = new URLSearchParams(window.location.search).get('shop');
    fetchSubscription(shop).then(data => {
      if (data && data.plan_name) {
        setCurrentPlan(data.plan_name.toLowerCase());  // Ensuring case consistency
      } else {
        console.error('No subscription data received:', data);
      }
    }).catch(error => {
      console.error('Failed to fetch subscription:', error);
    });
  }, []);

  const pricingOptions = [
    {
      title: 'Basic',
      price: '15.99',
      features: ['Corporation', 'Website', 'Collection', 'Itemlist', 'Product', 'Breadcrumbs'],
      plan: 'basic',
      illustration: '/assets/basic.png', // Use the imported image for basic
    },
    {
      title: 'Premium',
      price: '45.99',
      features: ['Basic +','Article', 'FAQ', 'Recipe', 'LocalBusinees', 'Video Object (soon)'],
      plan: 'premium',
      illustration: '/assets/premium.png',
    },
  ];

  const handleBuyClick = async (title, price) => {
    const shop = new URLSearchParams(window.location.search).get('shop');
    
    // Clear local storage when clicking buy or upgrade
    localStorage.clear();
    try {
      const response = await fetch(`/api/create_charge?shop=${shop}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          shop,
          package_name: title,
          package_price: price,
        })
      });

      if (response.ok) {
        const data = await response.json();
        window.parent.location.href = data.confirmation_url;
      } else {
        console.error('Failed to create charge:', await response.json());
      }
    } catch (error) {
      console.error('Error during fetch operation:', error);
    }
  };

  // Function to handle promotion code redemption
const redeemPromotionCode = async () => {
  const shop = new URLSearchParams(window.location.search).get('shop');
  try {
    const response = await fetch(`/api/redeem_promotion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shop,
        code: promotionCode,
      })
    });

    if (response.ok) {
      const data = await response.json();
      setPromoMessage(`Promotion redeemed! Free trial until ${new Date(data.free_trial_end).toLocaleDateString()}`);
      
      // Refresh the page after a short delay to allow user to read the message
      setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, 2000); // 2 seconds delay
    } else {
      const errorData = await response.json();
      setPromoMessage(`Error: ${errorData.error || 'Failed to redeem promotion code.'}`);
    }
  } catch (error) {
    setPromoMessage('Failed to redeem promotion code.');
  }
};

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <Card roundedAbove="sm">
            <Banner status={currentPlan ? 'success' : 'info'} title="Boost Your Traffic & Sales!">
              <Text as="p" variant="bodyMd">
                Choose a package to enhance your website's SEO without any coding or SEO knowledge.
                {currentPlan && (
                  <span>
                    You are currently subscribed to the <span className="currentPlanStyle">{currentPlan}</span> plan.
                  </span>
                )}
              </Text>
            </Banner>
          </Card>
        </Layout.Section>


        <Layout.Section>
          <BlockStack gap="800">
            <BlockStack inlineAlign="center">
              {pricingOptions.map((option, index) => (
                <CalloutCard
                  key={index}
                  title={option.title}
                  illustration={option.illustration} // Use the respective image for each plan
                  primaryAction={{
                    content: !currentPlan // No plan active
                      ? 'Buy'
                      : currentPlan === 'basic' && option.plan === 'basic' // Basic plan is active and it's the Basic button
                        ? 'Current Plan'
                        : currentPlan === 'basic' && option.plan === 'premium' // Basic plan active, show Upgrade for Premium
                          ? 'Upgrade to Premium'
                          : currentPlan === 'premium' && option.plan === 'premium' // Premium plan active and it's the Premium button
                            ? 'Current Plan'
                            : currentPlan === 'premium' && option.plan === 'basic' // Premium plan active and Basic button becomes Downgrade
                              ? 'Downgrade'
                              : 'Buy',
                    
                    onAction: currentPlan === option.plan // Disable click for current plan
                      ? null
                      : () => handleBuyClick(option.title, option.price), // Handle clicks for other options
                    
                    disabled: currentPlan === option.plan // Only disable if it's the current plan
                  }}
                >
                  <Text as="p" variant="headingMd" alignment="center">Features:</Text>
                  <ul>
                    {option.features.map((feature, idx) => <li key={idx}>{feature}</li>)}
                  </ul>
                  <Text as="p" variant="headingLg" fontWeight="bold">${option.price}</Text>
                </CalloutCard>
              ))}
            </BlockStack>
            <Divider />
          </BlockStack>
        </Layout.Section>
      </Layout>

      {/* Promotion code redemption section */}
      <Layout.Section>
        <BlockStack gap="800">
          <BlockStack allign="center">
            <Card roundedAbove="sm" background="bg-surface-secondary" title="Redeem Promotion Code" sectioned>
              <TextField
                label="Enter promotion code"
                value={promotionCode}
                onChange={(value) => setPromotionCode(value)}
                autoComplete="off"
                placeholder="Promo Code"
              />
              <Button onClick={redeemPromotionCode}>Redeem</Button>
              {promoMessage && <Banner status="info" title={promoMessage} />}
            </Card>
          </BlockStack>
          <Divider />
        </BlockStack>
      </Layout.Section>
    </Page>
  );
}
